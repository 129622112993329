import * as React from 'react';
import { Step } from 'src/types';
import Steps from 'src/components/Steps/Steps';

interface StackProps {
  name: string;
  steps: Step[];
  showLines?: boolean;
}

export default function Stack({ name, steps, showLines = true }: StackProps) {
  return (
    <div className="stack">
      <style jsx={true}>{`
        .stack {
          min-width: 180px;
          max-width: 200px;
        }
        .stack + .stack {
          @p: .ml38;
        }
        h3 {
          @p: .f14, .fw6, .black30, .ttu, .tracked, .nowrap, .relative;
          left: -4px;
          margin-bottom: 32px;
        }
      `}</style>
      <h3>{name}</h3>
      <Steps first={true} last={true} steps={steps} small={true} showDuration={false} showLines={showLines} />
    </div>
  );
}
