import React from 'react';
import { MDXProvider as OriginalMDXProvider } from '@mdx-js/react';
import Important from 'src/components/Tutorials/Important';
import Pre from 'src/components/Tutorials/Pre';
import Playground from 'src/components/Tutorials/Playground';
import Img from 'src/components/Tutorials/Img';
import A from 'src/components/Tutorials/A';
import { H1, H2, H3, H4, H5, H6, UL, LI, OL, Code, P, BlockQuote } from './MDXComponents';
import Quiz from 'src/components/Quiz/Quiz';
import { SelectedLanguageProvider } from './SelectedLanguageContext';

type Unregister = () => void;
type Register = (name: string, component: React.ReactNode) => Unregister;
interface MDXRegisterContextValue {
  register: Register;
}
export const MDXRegisterContext = React.createContext<MDXRegisterContextValue>({
  register: () => () => {
    // pass
  },
});

export const useCustomShortcode = (...params: Parameters<Register>) => {
  const unregister = React.useRef<ReturnType<Register> | null>(null);
  const { register } = React.useContext(MDXRegisterContext);
  React.useEffect(() => {
    return () => {
      if (unregister.current) {
        unregister.current();
        unregister.current = null;
      }
    };
  }, []);
  if (!unregister.current) {
    unregister.current = register(...params);
  }
  return;
};
interface Props {
  children: React.ReactNode;
}
const MDXProvider = ({ children }: Props) => {
  const [customComponents, setCustomComponents] = React.useState<{ [index: string]: React.ReactNode }>({});
  const register = React.useCallback<Register>((name, component) => {
    setCustomComponents(c => ({ ...c, [name]: component }));
    return () => {
      setCustomComponents(c => {
        if (c[name] === component) {
          const { [name]: old, ...other } = c;
          return other;
        } else return c;
      });
    };
  }, []);
  return (
    <SelectedLanguageProvider>
      <MDXRegisterContext.Provider value={{ register }}>
        <OriginalMDXProvider
          components={{
            a: A,
            img: Img,
            Important,
            Playground,
            pre: Pre,
            h1: H1,
            h2: H2,
            h3: H3,
            h4: H4,
            h5: H5,
            h6: H6,
            ul: UL,
            li: LI,
            ol: OL,
            code: Code,
            blockquote: BlockQuote,
            p: P,
            quiz: Quiz,
            ...customComponents,
          }}
        >
          {children}
        </OriginalMDXProvider>
      </MDXRegisterContext.Provider>
    </SelectedLanguageProvider>
  );
};

export default MDXProvider;
