import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Location } from '@reach/router';
import createPersistedState from 'use-persisted-state';
import debounce from 'debounce';
import { useAuth0, customClaims } from './auth';
import api from 'src/api';

export const useLocation = () => {
  const LocationContext = React.useMemo(() => {
    // DON'T TRY THIS AT HOME. REACHING INTO INTERNALS TO GET ACCESS TO THE LOCATION CONTEXT
    const Return = (Location as any)({});
    return Return.type._context || Return.type;
  }, []);
  const { location } = React.useContext(LocationContext);
  return location;
};

export const useLatestRef = <T,>(v: T): React.MutableRefObject<T> => {
  const ref = useRef(v);
  ref.current = v;
  return ref;
};

type DebouncedFn<Fn> = Fn & { clear: () => void } & { flush: () => void };
interface IUseDebounceOptions {
  delay: number;
  immediate?: boolean;
}
export const useDebounce = <A extends any[], R>(
  fn: (...args: A) => R,
  { delay, immediate }: IUseDebounceOptions,
): DebouncedFn<(...args: A) => R> => {
  /**
   * We need to always have a reference to the latest
   * value of the fn to run in the debounced function
   */
  const effect = useLatestRef(fn);

  /**
   * We need to debounce the returning function once and always have
   * a reference to it. so we use useRef to not create a new debouncedFn
   * every time.
   */
  const debouncedFn = useRef<DebouncedFn<(...args: A) => R>>();
  if (debouncedFn.current === undefined) {
    debouncedFn.current = debounce(
      (...args: A) => {
        return effect.current(...args);
      },
      delay,
      immediate,
    );
  }
  return debouncedFn.current;
};

export const useDebounceState = <T,>(
  value: T,
  { delay, immediate }: IUseDebounceOptions,
): [T, DebouncedFn<React.Dispatch<React.SetStateAction<T>>>] => {
  const [debouncedValue, setValue] = useState<T>(value);
  const debouncedSetValue = useDebounce(setValue, { delay, immediate });
  return [debouncedValue, debouncedSetValue];
};

export const useDebounceValue = <T,>(value: T, { delay, immediate }: IUseDebounceOptions): T => {
  const [debouncedValue, debouncedSetValue] = useDebounceState(value, {
    delay,
    immediate,
  });

  useEffect(() => {
    debouncedSetValue(value);
  }, [value, debouncedSetValue]);

  return debouncedValue;
};

export interface Activity {
  pathname?: string;
  scroll?: number;
}

const usePersistedLatestActivity = createPersistedState('latest-activity');
export const useLatestActivity = () => {
  const { user } = useAuth0();
  const [latestActivity, originalSetLatestActivity] = usePersistedLatestActivity<Activity>({});

  const setLatestActivity = useCallback(
    (newLatestActivity: Activity) => {
      if (user) {
        user[customClaims.latestActivity] = newLatestActivity;
        api.saveLatestActivity(user.sub, newLatestActivity);
      }
      originalSetLatestActivity(newLatestActivity);
    },
    [user],
  );

  useEffect(() => {
    if (!user) return;
    const userLatestActivity = user[customClaims.latestActivity];
    if (userLatestActivity) {
      setLatestActivity(userLatestActivity);
    }
  }, [user]);

  return [latestActivity, setLatestActivity] as const;
};
