import React from 'react';
import cn from 'classnames';
import createPersistedState from 'use-persisted-state';
import LinkButton from 'src/components/common/LinkButton';

const usePersistedShowingState = createPersistedState('show-cookie-agreement');
const CookieAgreement: React.FC = () => {
  const [isShowing, setShowing] = usePersistedShowingState<boolean>(true);

  return (
    <article className={cn('cookie-agreement', { 'cookie-agreement--showing': isShowing })}>
      <style jsx={true}>
        {`
          .cookie-agreement {
            @p: .ph12, .pv16, .overlayShadow;
            background-color: #797a7e;
            color: #fff;

            position: fixed;
            bottom: -100%;
            left: 0;
            right: 0;
            transition: all 500ms ease;
            z-index: 999;
          }
          .cookie-agreement--showing {
            bottom: 0;
          }
          .cookie-agreement__title {
            @p: .mb10;
          }
          .cookie-agreement__text {
            @p: .f14;
            line-height: 1.2;
          }
          .cookie-agreement__button {
            @p: .mt10;
          }
        `}
      </style>
      <h3 className="cookie-agreement__title">Cookie Consent</h3>
      <p className="cookie-agreement__text">
        By continuing to browse or by Clicking "Accept", you agree to the storing of cookies on your device to enhance
        your site experience and for analytical purpose. To learn more about how we use the cookies, please see our{' '}
        <a>cookies policy</a>
      </p>
      <div className="cookie-agreement__button">
        <LinkButton rounded={true} size="small" onClick={() => setShowing(false)}>
          Accept
        </LinkButton>
      </div>
    </article>
  );
};

export default CookieAgreement;
