import React, { FC, ReactNode, ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  selectable?: boolean;
  activeColor?: string;
  color?: string;
  children: ReactNode;
}

const ThreeDButton: FC<Props> = ({
  selected = false,
  selectable = true,
  activeColor = '#ffcb47',
  color = '#fff',
  children,
  ...extra
}) => (
  <button
    className={cn('pre-button', { 'non-selective': !selectable })}
    style={{ backgroundColor: selected ? activeColor : color }}
    {...extra}
  >
    <img className="background" src={require('../../assets/graphics/button-bg.svg')} draggable={false} />
    <style jsx={true}>{`
      .pre-button {
        @p: .pv10, .ph12, .black;
        position: relative;
        border: 1px #000 solid;
        overflow: visible;
        font-size: 90%;
        transition: transform 100ms ease-out;
        text-transform: capitalize;
      }
      .pre-button + .pre-button {
        @p: .ml20;
      }
      .pre-button:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
      .pre-button:active {
        outline: none;
      }
      .pre-button.non-selective:hover {
        cursor: default;
        transform: scale(1);
      }
      .pre-button.non-selective:focus {
        outline: none;
      }
      .background {
        position: absolute;
        width: 112%;
        height: 121%;
        right: -2%;
        top: -19%;
      }
    `}</style>
    {children}
  </button>
);

export default ThreeDButton;
