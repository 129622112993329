import React from 'react';
import { Course, Step } from '../types';
import { graphql } from 'gatsby';
import { ModuleSyllabusFragment, MdxSyllabusFragment } from 'types/graphql-types';
import { useCompletedCourses } from 'src/utils/completeCourse';

export interface IStepDescription {
  title?: string;
  duration?: number;
  body?: string;
}
interface CourseObj {
  id: string;
  name: string;
  description?: string;
  duration?: number;
  syllabus: Array<(ModuleSyllabusFragment & { t: 'Module' }) | (MdxSyllabusFragment & { t: 'Lesson' })>;
}
interface StepGroup {
  t: 'Module' | 'Lesson';
  description?: IStepDescription;
  steps: Step[];
}

const CourseContext = React.createContext<CourseObj | null>(null);
export const useCourse = () => React.useContext(CourseContext);

interface Props {
  course?: Course;
}
export const CourseProvider: React.FC<Props> = ({ course, children }) => {
  const transformedCourse = React.useMemo(() => {
    return course
      ? {
          ...course,
          syllabus: course.syllabus.map(s => {
            if (s?.internal.type === 'Module') {
              const m = s as ModuleSyllabusFragment;
              return { ...m, t: 'Module' };
            } else if (s?.internal.type === 'Mdx') {
              const mdx = s as MdxSyllabusFragment;
              return { ...mdx, t: 'Lesson' };
            }
          }),
        }
      : null;
  }, [course]) as CourseObj | null;

  return <CourseContext.Provider value={transformedCourse}>{children}</CourseContext.Provider>;
};

export const useExtractedSteps = () => {
  const course = useCourse();
  const [completedCourses] = useCompletedCourses();
  // This variable is for ssr mismatches
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  const isCompleted = (slug: any) => mounted && (completedCourses[slug]?.completed || false);
  const extractStepFromLesson = (lesson: MdxSyllabusFragment): Step => ({
    description: lesson.frontmatter?.description || undefined,
    duration: lesson.duration || undefined,
    link: lesson.fields?.slug as string,
    title: lesson.frontmatter?.title || '',
    completed: isCompleted(lesson.fields?.slug),
    comingSoon: false,
  });

  const data = React.useMemo(
    () =>
      course?.syllabus.reduce((prev, current, i, arr) => {
        if (current.t === 'Module') {
          const group: StepGroup = {
            t: 'Module',
            description: {
              title: current.name || undefined,
              duration: current.duration,
              body: current.description || undefined,
            },
            steps: current.lessons.map(l => extractStepFromLesson(l!)) as Step[],
          };
          return [...prev, group];
        } else {
          const extractedStep = extractStepFromLesson(current);
          const lastItem = prev[prev.length - 1];
          const description = !!lastItem
            ? undefined
            : { title: course.name, duration: course.duration, body: course.description };
          if (!lastItem || lastItem.t === 'Module') {
            const group: StepGroup = {
              t: 'Lesson',
              description,
              steps: [extractedStep],
            };
            return [...prev, group];
          } else {
            const group: StepGroup = {
              t: 'Lesson',
              description: lastItem.description,
              steps: [...lastItem.steps, extractedStep],
            };
            return [...prev.slice(0, prev.length - 1), group];
          }
        }
      }, [] as StepGroup[]),
    [course?.syllabus, mounted],
  );

  return data;
};

export const query = graphql`
  fragment CourseData on Course {
    id
    name
    logo
    description
    duration
    comingSoon
    audienceId
    syllabus {
      ... on Module {
        ...ModuleSyllabus
      }
      ... on Mdx {
        ...MdxSyllabus
      }
    }
  }
  fragment ModuleSyllabus on Module {
    id
    name
    description
    duration
    internal {
      type
    }
    lessons {
      ...PartialMdxData
    }
  }
  fragment MdxSyllabus on Mdx {
    ...PartialMdxData
  }
  fragment PartialMdxData on Mdx {
    id
    internal {
      type
    }
    duration
    frontmatter {
      description
      title
    }
    fields {
      slug
    }
    excerpt(pruneLength: 140)
  }
`;
