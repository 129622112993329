import React from 'react';
import cn from "classnames"

interface Props {
  small?: boolean;
}
export const ComingSoon: React.FC<Props> = ({small}) => (
  <p className={cn("coming-soon",{'coming-soon--small': small})}>
    <style jsx={true}>
      {`
        .coming-soon {
          display: inline-block;
          border: 1px solid $pink;
          border-radius: 2px;
          color: $pink;
          font-size: 10px;
          font-weight: bold;
          padding: 3px 7px;
        }
        .coming-soon--small {
          font-size: 8px;
          padding: 3px 5px;
        }
      `}
    </style>
    COMING SOON
      </p>
);
