import * as React from 'react';

interface Props extends React.ImgHTMLAttributes<unknown> {
  src: string;
}

export default function Img(props: Props) {
  const { src, ...rest } = props;
  const replacedSrc = src.replace('http://', 'https://');
  return (
    <div className="image-wrapper markdown-img">
      <style jsx={true}>{`
        .image-wrapper {
          @p: .justifyCenter, .flex, .itemsCenter;
          @p: .mt25, .mb38, .mhAuto;
          max-width: 600px;
        }
        img {
          max-width: 100%;
        }
        @media (max-width: 800px) {
          div.image-wrapper {
            width: 100%;
          }
        }
      `}</style>
      <img src={replacedSrc} {...rest} />
    </div>
  );
}
