const primaryRGB = '22,189,117';
exports.black = '#000';
exports.white = '#fff';
exports.green = 'rgba(39,174,96,1)';
exports.blue = 'rgba(42,126,210,1)';
exports.darkGreen = 'rgba(41, 46, 30)';
exports.darkBlue = 'rgba(23,42,58,1)';
exports.darkerBlue = 'rgba(15,32,46,1)';
exports.darkestBlue = 'rgb(11,20,28)';
exports.orange = 'rgba(207,92,54,1)';
exports.red = 'rgba(242,92,84,1)';
exports.accent = '#00b861';
exports.lightGreen = 'rgba(42,189,60,1)';
exports.lightBlue = 'rgba(74,183,255,1)';
exports.primary = `rgba(${primaryRGB},1)`;
exports.primary10 = `rgba(${primaryRGB},0.9)`;
exports.primary20 = `rgba(${primaryRGB},0.8)`;
exports.primary30 = `rgba(${primaryRGB},0.7)`;
exports.primary40 = `rgba(${primaryRGB},0.6)`;
exports.primary50 = `rgba(${primaryRGB},0.5)`;
exports.primary60 = `rgba(${primaryRGB},0.4)`;
exports.primary70 = `rgba(${primaryRGB},0.3)`;
exports.primary80 = `rgba(${primaryRGB},0.2)`;
exports.primary90 = `rgba(${primaryRGB},0.1)`;
exports.pink = 'rgb(224,0,130)';
exports.pink10 = 'rgba(224,0,130,0.1)';
exports.pink20 = 'rgba(224,0,130,0.2)';
exports.pink30 = 'rgba(224,0,130,0.3)';
exports.pink40 = 'rgba(224,0,130,0.4)';
exports.pink50 = 'rgba(224,0,130,0.5)';
exports.pink60 = 'rgba(224,0,130,0.6)';
exports.pink70 = 'rgba(224,0,130,0.7)';
exports.pink80 = 'rgba(224,0,130,0.8)';
exports.pink90 = 'rgba(224,0,130,0.9)';
exports.lightYellow = 'rgba(252,246,169,1)';
exports.lightYellow20 = 'rgba(252,246,169,0.2)';
exports.lightBrown = 'rgba(209,167,0,1)';
exports.toggleGreen = 'rgba(126, 211, 33, 1)';
exports.green0 = 'rgba(39,174,96,0)';
exports.green50 = 'rgba(39,174,96,.5)';
exports.lighterGreen = 'rgb(210, 242, 214)';
exports.lightGreen05 = 'rgba(42,189,60,.05)';
exports.lightGreen10 = 'rgba(42,189,60,.1)';
exports.lightGreen20 = 'rgba(42,189,60,.2)';
exports.lightGreen30 = 'rgba(42,189,60,.3)';
exports.lightGreen50 = 'rgba(42,189,60,.5)';
exports.blue0 = 'rgba(42,126,210,0)';
exports.blue10 = 'rgba(42,126,210,.1)';
exports.blue20 = 'rgba(42,126,210,.2)';
exports.blue50 = 'rgba(42,126,210,.5)';
exports.blue80 = 'rgba(42,126,210,.8)';
exports.darkBlue0 = 'rgba(23,42,58,0)';
exports.darkBlue02 = 'rgba(23,42,58,.02)';
exports.darkBlue04 = 'rgba(23,42,58,.04)';
exports.darkBlue05 = 'rgba(23,42,58,.05)';
exports.darkBlue06 = 'rgba(23,42,58,.06)';
exports.darkBlue07 = 'rgba(23,42,58,.07)';
exports.darkBlue10 = 'rgba(23,42,58,.10)';
exports.darkBlue20 = 'rgba(23,42,58,.20)';
exports.darkBlue30 = 'rgba(23,42,58,.30)';
exports.darkBlue40 = 'rgba(23,42,58,.40)';
exports.darkBlue50 = 'rgba(23,42,58,.5)';
exports.darkBlue60 = 'rgba(23,42,58,.6)';
exports.darkBlue70 = 'rgba(23,42,58,.7)';
exports.darkBlue80 = 'rgba(23,42,58,.8)';
exports.darkBlue90 = 'rgba(23,42,58,.9)';
exports.darkGreen0 = 'rgba(41, 46, 30,0)';
exports.darkGreen02 = 'rgba(41, 46, 30,.02)';
exports.darkGreen04 = 'rgba(41, 46, 30,.04)';
exports.darkGreen05 = 'rgba(41, 46, 30,.05)';
exports.darkGreen06 = 'rgba(41, 46, 30,.06)';
exports.darkGreen07 = 'rgba(41, 46, 30,.07)';
exports.darkGreen10 = 'rgba(41, 46, 30,.10)';
exports.darkGreen20 = 'rgba(41, 46, 30,.20)';
exports.darkGreen30 = 'rgba(41, 46, 30,.30)';
exports.darkGreen40 = 'rgba(41, 46, 30,.40)';
exports.darkGreen50 = 'rgba(41, 46, 30,.5)';
exports.darkGreen60 = 'rgba(41, 46, 30,.6)';
exports.darkGreen70 = 'rgba(41, 46, 30,.7)';
exports.darkGreen80 = 'rgba(41, 46, 30,.8)';
exports.darkGreen90 = 'rgba(41, 46, 30,.9)';
exports.darkerBlue0 = 'rgba(15,32,46,0)';
exports.darkerBlue50 = 'rgba(15,32,46,.5)';
exports.orange0 = 'rgba(207,92,54,0)';
exports.orange50 = 'rgba(207,92,54,.5)';
exports.red0 = 'rgba(242,92,84,0)';
exports.red10 = 'rgba(242,92,84,.1)';
exports.red20 = 'rgba(242,92,84,.2)';
exports.red30 = 'rgba(242,92,84,.3)';
exports.red40 = 'rgba(242,92,84,.4)';
exports.red50 = 'rgba(242,92,84,.5)';
exports.darkBlueGray = 'rgba(9,20,28,1)';
exports.gray02 = 'hsl(0,0%,98%)';
exports.gray04 = 'hsl(0,0%,96%)';
exports.gray07 = 'hsl(0,0%,93%)';
exports.gray10 = 'hsl(0,0%,80%)';
exports.gray20 = 'hsl(0,0%,70%)';
exports.gray30 = 'hsl(0,0%,60%)';
exports.gray40 = 'hsl(0,0%,50%)';
exports.gray50 = 'hsl(0,0%,50%)';
exports.gray60 = 'hsl(0,0%,40%)';
exports.gray70 = 'hsl(0,0%,30%)';
exports.gray80 = 'hsl(0,0%,20%)';
exports.gray90 = 'hsl(0,0%,10%)';
exports.white04 = 'hsl(0,0%,4%)';
exports.white07 = 'hsl(0,0%,7%)';
exports.white10 = 'hsl(0,0%,10%)';
exports.white20 = 'hsl(0,0%,20%)';
exports.white30 = 'hsl(0,0%,30%)';
exports.white40 = 'hsl(0,0%,40%)';
exports.white50 = 'hsl(0,0%,50%)';
exports.white60 = 'hsl(0,0%,60%)';
exports.white70 = 'hsl(0,0%,70%)';
exports.white80 = 'hsl(0,0%,80%)';
exports.white90 = 'hsl(0,0%,90%)';
exports.purple = 'rgba(164,3,111,1)';
exports.purple20 = 'rgba(164,3,111,.2)';
exports.lightOrange = 'rgba(241,143,1,1)';
exports.lightOrange10 = 'rgba(241,143,1,.1)';
exports.lightOrange20 = 'rgba(241,143,1,.2)';
exports.lightOrange30 = 'rgba(241,143,1,.3)';
exports.lightGray = 'rgba(195,191,196)';
exports.pblue = 'rgba(74,144,226,1)';
exports.pblue20 = 'rgba(74,144,226,0.2)';
exports.pred = 'rgba(208,2,27,1)';
exports.pred20 = 'rgba(208,2,27,0.2)';
exports.pbrown = 'rgba(174,145,0,1)';
exports.pyellow40 = 'rgba(248,231,28,0.4)';
exports.pgreen = 'rgba(39,174,96,1)';
exports.plightgreen50 = 'rgba(184,233,134,0.5)';
exports.size04 = '4px';
exports.size06 = '6px';
exports.size10 = '10px';
exports.size16 = '16px';
exports.size25 = '25px';
exports.size38 = '38px';
exports.size60 = '60px';
exports.size96 = '96px';
exports.size12 = '12px';
exports.size14 = '14px';
exports.copyLineheight = '1.5';
exports.titleLineheight = '1.3';
exports.duration = '.1s';
exports.size32 = '32px';
exports.size30 = '30px';
exports.size20 = '20px';
