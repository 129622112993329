import React from 'react';
import cn from 'classnames';
import { smoothScrollTo } from 'src/utils/smoothScroll';
import Checkmark from '../Checkmark';
import createPersistedState from 'use-persisted-state';
import { useLocation } from 'src/utils/hooks';
import { slugify } from 'src/utils/utils';
import ThreeDFrame from 'src/components/Tutorials/ThreeDFrame';

interface OwnProps {
  correctAnswer: number;
  question: string;
  answers: string[];
}

const Quiz: React.FC<OwnProps> = ({ correctAnswer, question, answers }) => {
  const { pathname } = useLocation();

  const useReactionState = React.useMemo(() => {
    return createPersistedState(pathname + '/' + slugify(question) + '/' + 'question');
  }, [question, pathname]);
  const [answerIndeces, setAnswerIndices] = useReactionState<number[]>([]);
  const answeredCorrectly = React.useMemo(() => answerIndeces.includes(correctAnswer), [answerIndeces, correctAnswer]);

  const scrollDown = () => {
    setTimeout(() => {
      const container = document.getElementById('tutorials-left-container');
      if (container) {
        smoothScrollTo(container, container.scrollTop + 200, 600);
      }
    }, 0);
  };

  const handleAnswerClick = (i: number) => {
    if (answeredCorrectly || answerIndeces.includes(i)) {
      return;
    }
    setAnswerIndices(a => [...a, i]);
    if (correctAnswer === i) {
      scrollDown();
    }
  };

  return (
    <div className={cn('quiz')}>
      <style jsx={true}>{`
        .quiz {
          @p: .mv38;
        }
        .question {
          @p: .mt25, .fw6, .lhTitle;
          max-width: 800px;
          font-size: 16px;
        }
        .answers {
          @p: .flex,.mt38;
          flex-wrap: wrap;
        }
        .container {
          @p: .center, .mb38;
          max-width: 924px;
        }
        .skip {
          @p: .underline, .black30, .tc, .mt38, .pointer, .mb60;
        }
        .next-chapters {
          @p: .flex, .justifyBetween;
        }
        .quiz :global(pre) {
          @p: .bDarkBlue10, .ba, .br2, .mv0, .ph16, .bgDarkBlue04;
          line-height: 0.6;
          margin: 0 auto;
        }
        .quiz :global(code) {
          @p: .mono;
          font-size: 13px;
          font-weight: 500;
          line-height: 1;
          border: 0;
          padding: 0;
        }

        .question > :global(div) {
          @p: .mt20;
          width: 100%;
        }

        .question > :global(img) {
          @p: .mt20;
          width: 100%;
          max-width: 600px;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        }

        @media (max-width: 580px) {
          div.separator-line {
            @p: .justifyStart;
          }
          div.separator-line .title {
            margin-left: 21px;
          }
          div.answer-row {
            @p: .db, .mt0;
          }
          div.question {
            @p: .f20;
          }
          div.answers {
            @p: .mt38;
          }
          div.next-chapters {
            @p: .db;
          }
        }
      `}</style>

      <ThreeDFrame title="Practice">
        <div className="question" dangerouslySetInnerHTML={{ __html: question }} />
        <div className="container">
          <div className="answers">
            {answers.map((answer, index) => (
              <Answer
                text={answer}
                onClick={() => handleAnswerClick(index)}
                checked={answerIndeces.includes(index)}
                correct={correctAnswer === index}
                key={answer}
              />
            ))}
          </div>
        </div>
      </ThreeDFrame>
    </div>
  );
};

interface AnswerProps {
  text: string;
  onClick: () => void;
  checked: boolean;
  correct: boolean;
}

function Answer({ text, onClick, checked, correct }: AnswerProps) {
  return (
    <div className={cn('answer', { checked, correct })} onClick={onClick}>
      <style jsx={true}>{`
        .answer {
          @p: .flex, .itemsStart, .w50, .pointer, .lhCopy,.mb25;
          align-items: center;
        }
        span {
          @p: .ml16, .black50, .f16;
        }
        .answer.checked.correct span {
          @p: .primary;
        }
        .answer.checked:not(.correct) span {
          @p: .strike, .black20;
        } /* avoids jumping of content when toggled */
        .checkmark {
          @p: .relative;
          top: 2px;
          width: 24px;
          height: 24px;
        }
        @media (max-width: 580px) {
          div.answer {
            @p: .w100;
          }
          div.answer span {
            @p: .ml10;
          }
          div.answer + div.answer {
            @p: .ml0;
          }
        }
      `}</style>
      <div className="checkmark">
        <Checkmark checked={checked && correct} crossed={checked && !correct} />
      </div>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

export default Quiz;
