import * as React from 'react';
import Icon from 'src/components/Icon';
import * as $v from 'src/variables';
import Link from 'gatsby-link';
import css from 'styled-jsx/css';

const linkStyle = css.resolve`
  a + a {
    @p: .ml10;
  }
`;

const footerLinks = [
  [
    {
      text: 'About Us',
      href: '#',
    },
    {
      text: 'Contact Us',
      href: '/contact-us',
    },
    {
      text: 'Blog',
      external: true,
      href: 'https://blog.cauzl.com',
    },
  ],
  [
    {
      text: 'Privacy Policy',
      href: '/privacy',
    },
    {
      text: 'Terms of Use',
      href: '/terms',
    },
  ],
];

export default function Footer() {
  return (
    <div className="footer">
      {linkStyle.styles}
      <style jsx={true}>{`
        .footer {
          @p: .tc, .pv38, .black40, .relative, .z2;
          background-color: rgba(0, 0, 0, 0.03);
          padding-left: 30px;
          padding-right: 30px;
          line-height: 1.5;
        }
        .love {
          @p: .red;
        }
        .logos {
          @p: .flex, .justifyCenter, .mt25;
        }
        .logos :global(*) + :global(*) {
          @p: .ml16;
        }
        img {
          height: 28px;
          width: auto;
        }
        .footer :global(a) {
          @p: .black40;
        }
        .footer :global(a:hover) {
          @p: .underline;
        }
        .links {
          @p: .f14, .mt16;
        }
        .links-row + .links-row {
          @p: .mt6;
        }
        .link-separator {
          @p: .mh6;
        }
      `}</style>
      <span>
        Made with <span className="love"> ♥ </span> by{' '} Edgur
        {' '}
      </span>
      <div className="logos">
        <a href="https://twitter.com/_cauzl" target="_blank">
          <img src={require('../../assets/icons/twitter.svg')} alt="" />
        </a>
      </div>
      <div className="links">
        {footerLinks.map((linkRow, index) => (
          <div className="links-row" key={index}>
            {linkRow.map((link, index) => {
              const LinkComponent = (props: any) =>
                link.external ? <a href={link.href} {...props} /> : <Link to={link.href} {...props} />;
              return (
                <>
                  <LinkComponent key={link.text} className={linkStyle.className} to={link.href}>
                    {link.text}
                  </LinkComponent>
                  {index !== linkRow.length - 1 && <span className="link-separator">|</span>}
                </>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
