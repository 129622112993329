import * as React from 'react';
import Copy from './Copy';
import * as $v from 'src/variables';
import Icon from 'src/components/Icon';

interface Props {
  text: string;
}

export default function CopyButton({ text }: Props) {
  return (
    <Copy text={text}>
      <Icon src={require('src/icons/fill/copy.svg')} color={$v.gray30} width={38} height={38} />
    </Copy>
  );
}
