import React, { Dispatch, SetStateAction, createContext, FC, useContext } from 'react';
import createPersistedState from 'use-persisted-state';

type SelectedLanguageContextValue = [string, Dispatch<SetStateAction<string>>];

export const allMultiLanguages = ['r', 'python', 'stata'];
const SelectedLanguageContext = createContext<SelectedLanguageContextValue>([
  allMultiLanguages[0],
  () => {
    // Pass
  },
]);

const usePersistedLanguageState = createPersistedState('selected-language');
export const SelectedLanguageProvider: FC = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = usePersistedLanguageState(allMultiLanguages[0]);
  return (
    <SelectedLanguageContext.Provider value={[selectedLanguage, setSelectedLanguage]}>
      {children}
    </SelectedLanguageContext.Provider>
  );
};

export const useSelectedLanguage = () => {
  return useContext(SelectedLanguageContext);
};
