import * as React from 'react';
import Link from 'gatsby-link';

interface Props {
  href: string;
  [key: string]: any;
}

export default function A({ href, children, className, ...rest }: Props) {
  // use gatsby link for internal links
  const mergedClassname = [className, 'markdown-a'].join(' ');
  if (href && typeof href.startsWith === 'function' && !href.startsWith('http')) {
    return (
      <Link className={mergedClassname} to={href} {...rest}>
        {children}
      </Link>
    );
  }

  // use normal link component for rest, but open new tab
  return (
    <a className={mergedClassname} href={href} {...rest} target="_blank">
      {children}
    </a>
  );
}
