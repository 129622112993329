import React from 'react';
import cn from 'classnames';

interface Props {
  duration: number;
  total?: boolean;
  dark?: boolean;
  className?: string;
  link?: string;
  isDescription?: boolean;
}

export default function Duration({ duration, total, dark, className, link, isDescription = false }: Props) {
  return (
    <div className={cn('time', className, { dark, 'description-duration': isDescription })}>
      <style jsx={true}>{`
        .time {
          @p: .ttu, .black30, .ml10, .itemsCenter, .flex, .fw6, .relative, .flexFixed;
          font-size: 15px;
        }
        .time.dark {
          @p: .white30;
        }
        .time img {
          width: 15px;
          height: 15px;
        }
        .time span {
          @p: .ml6;
        }
        .time.dark.description-duration span {
          @p: .white30;
        }
        .time.first {
          top: -3px;
        }
        @media (max-width: 500px) {
          div.time {
            display: none;
          }
        }
      `}</style>
      <img src={require('../assets/icons/play.svg')} alt="" />
      <span>
        {milisecondsToMinutes(duration)} MIN {total ? 'TOTAL' : ''}
      </span>
    </div>
  );
}

const milisecondsToMinutes = (ms: number) => {
  const totalSeconds = ms / 1000;
  const minutes = Math.floor(totalSeconds / 60);
  return minutes;
};
