import React from 'react';
import ThreeDFrame from './ThreeDFrame';

interface ImportantProps {
  children: React.ReactNode;
}

export default function Important({ children }: ImportantProps) {
  return (
    <div className="instruction-wrapper">
      <style jsx={true}>{`
        .instruction-wrapper {
          @p: .mv38;
        }
        .instruction-container {
          @p: .pv25;
        }
      `}</style>
      <ThreeDFrame title="Important" titleColor={'#f3e9d2'}>
        <div className="instruction-container">{children}</div>
      </ThreeDFrame>
    </div>
  );
}
