import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { defaultReaction, QuizReaction, QuizState } from 'src/reducers/quiz';
import Checkmark from 'src/components/Checkmark';
import Icon from 'src/components/Icon';
import * as $v from 'src/variables';

interface Props {
  children?: JSX.Element;
  light?: boolean;
  first?: boolean;
  last?: boolean;
  active?: boolean;
  done?: boolean;
  highlightFirst?: boolean;
  showLine?: boolean;
  disabled?: boolean;
}

const DottedListItem = ({
  children,
  light,
  first,
  last,
  active,
  done = false,
  highlightFirst,
  showLine = true,
  disabled = false,
}: Props & QuizReaction) => {
  return (
    <div
      className={cn('dotted-list-item', {
        active,
        done,
        first,
        highlightFirst,
        last,
        light,
        showLine,
        disabled,
      })}
    >
      <style jsx={true}>{`
        .dotted-list-item {
          @p: .f16, .h100, .pl25, .flex, .itemsCenter, .relative;
          transition: color 0.15s ease-in-out;
        }
        .dotted-list-item.first {
          padding-top: 0px !important;
        }
        .dotted-list-item.last {
          padding-bottom: 0px !important;
        }
        .dotted-list-item.first.showLine {
          margin-top: -1.1em;
        }
        .dotted-list-item.last.showLine {
          margin-bottom: -1.1em;
        }

        .dotted-list-item:not(.light) {
          @p: .white80;
        }
        .dotted-list-item.light {
          @p: .black80;
          border-color: $gray20;
        }

        .dotted-list-item:not(.done):hover {
          color: $primary !important;
        }
        .dotted-list-item.disabled {
          color: rgba(0, 0, 0, 0.5) !important;
        }

        .dotted-list-item.disabled:hover {
          color: rgba(0, 0, 0, 0.5) !important;
          cursor: default;
        }
        /* play styles */
        .play {
          @p: .absolute, .ba, .bw2, .br100, .z2, .flex, .itemsCenter, .justifyCenter;
          background: rgba(224, 0, 130, 0);
          content: '';
          left: -7px;
          width: 12px;
          height: 12px;
          transition: border-color 0.15s ease-in-out;
        }
        .dotted-list-item.light .play {
          @p: .bgWhite, .bBlack20;
        }
        .dotted-list-item:not(.light) .play {
          @p: .bWhite20, .bgDarkGreen;
        }
        .dotted-list-item:not(.done):not(.disabled):hover .play {
          border-color: $primary;
          left: -13px;
          width: 20px;
          height: 20px;
        }
        .play .icon {
          @p: .o0, .z3;
          margin-left: 2px;
          visibility: hidden;
          pointer-events: none;
          transition: opacity 0.25s ease-in-out;
        }
        .dotted-list-item:not(.done):not(.disabled):hover .play .icon {
          @p: .o100;
          visibility: visible;
          pointer-events: all;
          transition: none;
        }

        /* checkmark styles */
        .checkmark {
          @p: .absolute, .z2;
          left: -11px;
        }

        /* first styles */
        .dotted-list-item.first.light.highlightFirst::after,
        .dotted-list-item.first.light.done::after {
          @p: .db, .absolute;
          content: '';
          height: 30px;
          margin-top: -9px;
          left: -2px;
          background-image: linear-gradient(to top, rgba(225, 225, 225, 0), $primary);
          width: 2px;
        }

        /* before and after glow style */
        .dotted-list-item:not(.first) .before-glow {
          @p: .db, .absolute;
          height: 100%;
          top: -21px;
          left: -2px;
          background-image: linear-gradient(to top, $primary 20%, rgba(225, 225, 225, 0));
          width: 2px;
        }
        .dotted-list-item.last .before-glow {
          height: 100%;
          top: -12px;
        }
        .dotted-list-item .after-glow {
          @p: .db, .absolute;
          height: 100%;
          left: -2px;
          top: 10px;
          background-image: linear-gradient(to top, rgba(225, 225, 225, 0), $primary 80%);
          width: 2px;
        }
        .dotted-list-item.first .after-glow {
          height: calc(100% - 10px);
          top: 12px;
        }
      `}</style>

      <div className="play">
        <div className="icon">
          <Icon src={require('../../assets/icons/video.svg')} width={10} height={10} color={$v.primary} />
        </div>
      </div>
      {done && (
        <div className="checkmark">
          <Checkmark checked={true} />
        </div>
      )}
      {showLine && (done || active) && <div className="before-glow" />}
      {children}
      {showLine && (done || active) && !last && <div className="after-glow" />}
    </div>
  );
};

export default DottedListItem;
