import * as React from 'react';
import Link from 'gatsby-link';

export default function Logo() {
  return (
    <div className="logo-wrapper">
      <style jsx={true}>{`
        .logo {
          @p: .pv16, .ph25, .flex, .fw6, .itemsCenter;
          color: $primary;
          z-index: 10102;
          font-size: 17px;
        }
        .logo img {
          @p: .flexFixed, .rozha-font;
          width: 25px;
          height: 25px;
        }
      `}</style>
      <Link to="/">
        <div className="logo">
          <img src={require('../assets/icons/cauzl_logo.svg')} />
        </div>
      </Link>
    </div>
  );
}
