import * as React from 'react';
import Header from './Header';
// import Search from './Search/Search';
import { Post, Course, Module } from 'src/types';
import Logo from './Logo';
import 'src/utils/polyfill';
import MDXProvider from 'src/components/misc/MDXProvider';
import MarkdownStyles from './MarkdownStyles';
import { CourseProvider } from 'src/components/CourseProvider';
import CookieAgreement from 'src/components/CookieAgreement';
import { useStaticQuery, graphql } from 'gatsby';
import { GetCookieAgreementVisibilityQuery } from 'types/graphql-types';

interface Props {
  children?: React.ReactNode;
  history: any;
  post?: Post;
  course?: Course;
  module?: Module;
}

export default function App({ children, history, post, course }: Props) {
  const { site } = useStaticQuery<GetCookieAgreementVisibilityQuery>(graphql`
    query GetCookieAgreementVisibility {
      site {
        siteMetadata {
          showCookieAgreement
        }
      }
    }
  `);
  return (
    <div className="container">
      <style jsx={true} global={true}>{`
        /* reset.css */
        body {
          height: 100vh;
        }
        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          vertical-align: baseline;
        } /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
          display: block;
        }
        body {
          line-height: 1;
        }
        ol,
        ul {
          list-style: none;
        }
        blockquote,
        q {
          quotes: none;
        }
        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: '';
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        } /* main rules */
        body,
        input,
        select,
        option,
        textarea {
          font-family: 'Open Sans', sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        input,
        select,
        options,
        textarea {
          outline: none;
        }
        h1 {
          @p: .f38, .lhTitle;
        }
        h2 {
          @p: .lhTitle;
          font-size: 35px;
        }
        a {
          @p: .noUnderline, .lhCopy;
        }
        section {
          @p: .pt96;
        }
        section h2 {
          @p: .tc;
        } /* Reusable Components */
        .btn {
          @p: .white, .f25, .fw6, .dib, .lhTitle, .pointer;
          background: linear-gradient(103deg, $primary90, $primary);
          background-color: $primary;
          background-position: 0 0;
          background-repeat: no-repeat;
          border: solid 2px $primary90;
          padding: 17px 30px 19px;
          border-radius: 6px;
          transition: background-position 0.25s ease, box-shadow 0.25s ease;
        }
        .btn.green {
          @p: .white;
          background-color: $green;
          background-image: linear-gradient(101deg, #33bc7f, #27ae60);
          border: solid 2px #27ae60;
        }
        .btn.green:hover {
          border: solid 2px #27ae60;
        }
        .btn:hover {
          background-position: -300px;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        }
        .btn.passive {
          @p: .ba, .bw2, .primary;
          background: white;
          border-color: $primary30;
        }
        .btn.small {
          @p: .f20, .fw6, .pv16, .ph20;
        }
        @media (max-width: 500px) {
          div div.btn {
            padding: 10px 16px !important;
          }
          h2 {
            font-size: 32px;
            text-align: left;
          }
          section {
            padding-top: 38px !important;
          }
        }
      `}</style>
      <style jsx={true}>{`
        .container {
          @p: .flex, .flexColumn;
          height: 100vh;
        }
        .content {
          @p: .flex1;
        }
        .mobile-logo {
          @p: .fixed, .top0, .left0, .dn;
          z-index: 10150;
        }
        @media (max-width: 1050px) {
          div.mobile-logo {
          }
        }
      `}</style>
      <MDXProvider>
        <CourseProvider course={course}>
          <MarkdownStyles />
          {/* <Search /> */}
          <Header />
          <div className="mobile-logo">
            <Logo />
          </div>
          <div className="content">{children}</div>
          {site?.siteMetadata?.showCookieAgreement && <CookieAgreement />}
        </CourseProvider>
      </MDXProvider>
    </div>
  );
}
