import React from 'react';
import cn from 'classnames';
import { IStepDescription } from '../CourseProvider';
import Duration from '../Duration';

interface StepDescriptionProps {
  description: IStepDescription;
  dark?: boolean;
  showDuration?: boolean;
}
const StepDescription: React.FC<StepDescriptionProps> = ({ description, dark = false, showDuration = true }) => {
  return (
    <div className={cn('desc', { dark })}>
      <style jsx={true}>{`
        .desc {
          @p: .w100, .h100, .tr, .flex,  .flexColumn, .itemsEnd, .justifyStart;
        }
        .desc.dark {
          @p: .white30;
        }
        .desc:not(.dark) .title {
          @p: .black50;
        }

        .desc:not(.dark) p {
          @p: .black40;
        }

        .desc .title {
          @p: .f20, .pr38;
        }
        .desc p {
          @p: .f16, .mt16, .lhCopy, .pr38;
          max-width: 320px;
        }
        .desc .duration {
          @p: .justifyEnd, .pr38, .mt16;
        }
        .desc.dark .title {
          @p: .white70;
        }
        .desc.dark p {
          @p: .white30;
        }
        @media (max-width: 500px) {
          .desc .duration {
            display: none;
          }
        }
      `}</style>
      <h3 className="title">{description.title}</h3>
      {!!description.duration && showDuration && (
        <div className="duration">
          <Duration dark={dark} duration={description.duration} total={true} isDescription={true} />
        </div>
      )}
      <p className="body">{description.body}</p>
    </div>
  );
};

export default StepDescription;
