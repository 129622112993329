import React from 'react';
import cn from 'classnames';

interface DropdownProps {
  children?: React.ReactNode;
  show?: boolean;
}

const Dropdown = ({ children, show = false }: DropdownProps) => {
  const [direction, setDirection] = React.useState<'right' | 'left'>('left');
  const div = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const x = div.current?.getBoundingClientRect()?.x;
    if (x && x < 100) {
      setDirection('right');
    } else {
      setDirection('left');
    }
  }, [div.current]);
  return (
    <>
      <style jsx={true}>{`
        .dropdown {
          @p: .o0, .z999;
          transition: opacity ease-in-out 0.1s;
          pointer-events: none;
        }
        .container {
          @p: .absolute;
          top: 100%;
        }
        .container.left {
          right: 0;
        }
        .container.right {
          left: 0;
        }
        .big-triangle {
          @p: .absolute;
          top: 100%;
          right: 50%;
          transform: translate(50%, -90%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 13px 16px 13px;
          border-color: transparent transparent #e5e5e5 transparent;
        }
        .small-triangle {
          @p: .absolute;
          right: 50%;
          top: 100%;
          transform: translate(50%, -90%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 12.5px 10px;
          border-color: transparent transparent #ffffff transparent;
        }
        .content {
          @p: .relative, .bgWhite, .pa38, .ba, .bw2, .br2, .bBlack10;
        }
      `}</style>
      <div className={cn('big-triangle', 'dropdown')} />
      <div ref={div} className={cn('container', 'dropdown', direction)}>
        <div className="content">{children}</div>
      </div>
      <div className={cn('small-triangle', 'dropdown')} />
    </>
  );
};

export default Dropdown;
