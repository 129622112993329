import React, { FC, useMemo, useEffect, useRef } from 'react';
import cn from 'classnames';
import CopyButton from 'src/components/CopyButton';
import * as $v from 'src/variables';
import Icon from 'src/components/Icon';
import { extractGroup } from 'src/utils/graphql';
import isServerSide from 'src/utils/isServerSide';
import { allMultiLanguages, useSelectedLanguage } from '../misc/SelectedLanguageContext';
import ThreeDButton from './ThreeDButton';

interface Props {
  children?: React.ReactNode;
  path?: string;
  className?: string;
  nocopy?: string;
}

function getGithubLink(group: string, path?: string) {
  if (!path) {
    return '';
  }

  // remove all preceding dots and slashes
  const trimmedPath = path.replace(/^[\.\/]*/g, '');

  // we assume that the path always includes the directory of the app itself like follows:
  // .../hackernews-react-apollo/src/styles/index.css
  const firstSlash = trimmedPath.indexOf('/');
  const normalizedPath = trimmedPath.slice(firstSlash + 1, trimmedPath.length);

  return `https://github.com/howtographql/${group}/blob/master/${normalizedPath}`;
}

export default function Pre(props: Props) {
  const { children, path, className, nocopy, ...rest } = props;

  const [selectedLanguage, setSelectedLanguage] = useSelectedLanguage();
  const isSelected = className?.includes(`language-multi-${selectedLanguage}`);
  const isMulti = allMultiLanguages.some(lang => className?.includes(`language-multi-${lang}`));
  const preRef = useRef<HTMLDivElement>(null);

  const isBash = className ? className.includes('bash') : false;
  const group = isServerSide() ? '' : extractGroup(window.location.pathname);
  const showCopy = nocopy ? !(nocopy === 'true') : true;
  const link = getGithubLink(group, path);

  useEffect(() => {
    if (!preRef || !preRef.current) return;
    const parentElement = preRef.current.parentElement;
    if (!parentElement) return;
    parentElement.style.display = isMulti && !isSelected ? 'none' : 'block';
  }, [isMulti, isSelected]);

  const PathLink = useMemo(
    () => (
      <a className="path" href={link} target="_blank">
        {isBash ? (
          <span className="path-sh">
            <span>$</span>
            <svg width="4" height="12" viewBox="0 0 4 12" xmlns="http://www.w3.org/2000/svg" fill="rgba(0,0,0,0.2)">
              <rect x="0" y="0" width="4" height="12" />
            </svg>
          </span>
        ) : (
          <Icon src={require('src/icons/fill/github.svg')} color={$v.gray30} width={14} height={14} />
        )}
        <span>{path}</span>
      </a>
    ),
    [isBash, link, path],
  );

  return (
    <div className={cn(`pre-container`, { multi: isMulti })} ref={preRef}>
      <style jsx={true}>{`
        .pre-container {
          @p: .mb38, .pa25, .w100;
          margin-top: 50px;
          position: relative;
          border: 1px #000 solid;
        }
        pre {
          @p: .bDarkBlue10, .ba, .br2, .ph16, .bgDarkBlue04;
          overflow: visible;
        }
        pre :global(code) {
          @p: .mono;
          font-size: 13px;
          font-weight: 500;
          border: 0;
          padding: 0;
        }
        .copy-button {
          @p: .absolute, .right0, .bottom0, .pa10, .z999, .pointer;
        }
        .copy-button :global(svg) {
          transition: $duration fill;
        }
        .copy-button:hover :global(svg) {
          fill: $gray60 !important;
        }
        .path {
          @p: .flex, .itemsCenter;
        }
        .path span {
          @p: .black40, .fw6, .f12;
        }
        .path-sh {
          @p: .mono, .flex, .itemsCenter;
        }
        .path-sh svg {
          @p: .mr6;
          margin-left: 2px;
        }
        .path :global(i) {
          @p: .mr6;
        }

        .child-container {
          width: 100%;
          height: 100%;
          overflow: auto;
        }
      `}</style>
      {isMulti && (
        <LanguageTabs
          languages={allMultiLanguages}
          onSelect={lang => setSelectedLanguage(lang)}
          isSelected={lang => lang === selectedLanguage}
        />
      )}
      {path && PathLink}
      <pre className={className} {...rest}>
        <div className="child-container">{children}</div>
        {showCopy && (
          <span className="copy-button">
            <CopyButton text={childrenToString(children)} />
          </span>
        )}
      </pre>
    </div>
  );
}

export function childrenToString(children: React.ReactNode | undefined): string {
  if (typeof children === 'string') {
    return children;
  }

  if (typeof children === 'undefined') {
    return '';
  }

  if (Array.isArray(children)) {
    return children
      .map((el: any) => {
        if (typeof el === 'string') {
          return el;
        } else if (el) {
          return childrenToString(el.props.children);
        }
      })
      .join('');
  }

  if ((children as any)?.props?.children) return childrenToString((children as any)?.props?.children);

  return '';
}

interface LanguageTabsProps {
  languages: string[];
  onSelect: (language: string) => void;
  isSelected: (language: string) => boolean;
}

const LanguageTabs: FC<LanguageTabsProps> = ({ languages, onSelect, isSelected }) => {
  return (
    <div className="language-tab">
      <style jsx={true}>
        {`
          .language-tab {
            position: absolute;
            top: -25px;
            left: 25px;
          }
        `}
      </style>
      {languages.map(language => (
        <ThreeDButton
          key={language}
          onClick={() => onSelect(language)}
          selected={isSelected(language)}
          activeColor={'#60caff'}
        >
          {language}
        </ThreeDButton>
      ))}
    </div>
  );
};
