import React from 'react';
import { AuthButton, useAuth0 } from 'src/utils/auth';
import Dropdown from './Dropdown';
import { Link, navigate } from 'gatsby';
import LinkButton from 'src/components/common/LinkButton';

const LoginButton = () => {
  const isTouch = React.useRef<boolean>(false);
  const { user: auth0User = {}, loading } = useAuth0();

  const isFromDatabase = auth0User?.connectionType === 'auth0';

  return (
    <>
      <style jsx={true}>
        {`
          .login {
            @p: .bgPrimary, .ph38, .pv10, .black80, .white, .ba;
            border-radius: 5px;
          }
          .login:hover {
            @p: .primary, .bgWhite, .ba;
          }
          .avatar {
            @p: .overflowHidden, .flex, .itemsCenter, .justifyCenter, .br100;
            width: 38px;
            height: 38px;
          }
          .avatar img {
            @p: .hAuto, .w100;
          }
          .item {
            @p: .f16, .lhCopy, .link, .black;
            font-size: 16px;
          }
          .item:not(.last) {
            @p: .pb12;
          }
          .item:hover {
            @p: .primary;
          }
          h3 {
            @p: .f14, .fw6, .black30, .ttu, .tracked, .nowrap, .relative;
            left: -4px;
            margin-bottom: 32px;
          }
        `}
      </style>
      <AuthButton
        renderLoading={() => (
          <div>
            <span>   </span>
          </div>
        )}
        renderLoggedIn={(user, logout) => {
          const displayName = user?.nickname || user?.name;
          return (
            <div>
              <div
                onTouchStart={e => {
                  isTouch.current = true;
                }}
                onClick={() => {
                  if (!isTouch.current) {
                    navigate('/profile');
                  }
                }}
              >
                {user.picture ? (
                  <span className="avatar">
                    <img src={user.picture} alt="user profile" />
                  </span>
                ) : (
                  <span>{displayName}</span>
                )}
              </div>
              <Dropdown>
                {user.picture ? <h3>{displayName}</h3> : null}
                <Link to="/profile">
                  <div className="item">
                    <span>Profile</span>
                  </div>
                </Link>
                {!loading && isFromDatabase && (
                  <Link to="/change-password">
                    <div className="item">
                      <span>Change Password</span>
                    </div>
                  </Link>
                )}
                <div className="item last">
                  <span onClick={() => logout()}>Logout</span>
                </div>
              </Dropdown>
            </div>
          );
        }}
        renderLoggedOut={login => (
          <LinkButton rounded={true} bold={true} onClick={login}>
            Login
          </LinkButton>
        )}
      />
    </>
  );
};

export default LoginButton;
