import React, { MouseEvent, ReactNode, CSSProperties } from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import css from 'styled-jsx/css';

interface Props {
  type?: 'primary' | 'secondary' | 'danger';
  size?: 'default' | 'small' | 'large';
  bold?: boolean;
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => void;
  to?: string;
  style?: CSSProperties;
  dashed?: boolean;
  rounded?: boolean;
}

const buttonStyle = css.resolve`
  .button {
    @p: .pv8, .ph16, .pointer, .white;
    display: block;
    line-height: 1;
    font-size: 100%;
    border: none;
    outline: none;
  }
  .button:hover {
    @p: .buttonShadow;
  }
  .button.primary {
    @p: .bgPrimary;
  }
  .button.secondary {
    @p: .bgBlack40;
  }
  .button.danger {
    @p: .bgRed;
  }
  .button.large {
    @p: .pv20, .ph25;
    font-size: 140%;
    font-weight: 600;
  }
  .button.small {
    @p: .pv8, .ph16;
    font-size: 70%;
  }
  .button.bold {
    font-weight: bold;
  }
  .button.rounded {
    border-radius: 3px;
  }
  .button.large.rounded {
    border-radius: 5px;
  }
  .button.small.rounded {
    border-radius: 2px;
  }
  @media (max-width: 500px) {
    .button.large {
      font-size: 120%;
      line-height: 1.2;
    }
  }
`;

const LinkButton: React.FC<Props> = ({
  children,
  type = 'primary',
  size = 'default',
  bold = false,
  dashed = false,
  rounded = false,
  onClick,
  to = '',
  style,
  ...extra
}) => {
  const classNames = cn('button', buttonStyle.className, { [type]: true, [size]: true, bold, rounded });

  return (
    <div className={cn('button-container', { dashed })}>
      <style jsx={true}>
        {`
          .button-container {
            display: inline-block;
          }
          .button-container.dashed {
            padding: 8px 0 0 8px;
          }
          .button-wrapper {
            position: relative;
          }
          .button-dashed {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            margin: -5% 0 0 -5%;
            z-index: -1;
            border: 1.2px #666 dashed;
          }
        `}
      </style>
      <div className="button-wrapper">
        {buttonStyle.styles}
        {dashed && <div className="button-dashed" />}
        {to ? (
          <Link to={to} className={classNames} onClick={onClick} {...extra} style={style}>
            {children}
          </Link>
        ) : (
          <button className={classNames + ' ' + buttonStyle.className} onClick={onClick} {...extra} style={style}>
            {children}
          </button>
        )}
      </div>
    </div>
  );
};

export default LinkButton;
