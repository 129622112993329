import React, { FC, ReactNode } from 'react';
import ThreeDButton from './ThreeDButton';

interface Props {
  title: string;
  titleColor?: string;
  children: ReactNode;
}

const ThreeDFrame: FC<Props> = ({ title, titleColor = '#FFCB47', children }) => {
  return (
    <div className="threeDFrameContainer">
      <style jsx={true}>
        {`
          .threeDFrameContainer {
            padding-top: 32px;
          }
          .threeDFrame {
            @p: .pv12, .ba, .bBlack;
            position: relative;
            padding-left: 30px;
            padding-right: 30px;
          }
          .threeDFrameTitle {
            position: absolute;
            top: -25px;
            left: 30px;
            display: inline-block;
            overflow: visible;
          }
          .title-background {
            position: absolute;
            width: 115%;
            height: 112%;
            right: -14%;
            top: -8%;
          }
          @media (max-width: 580px) {
            .threeDFrame {
              padding-left: 20px;
              padding-right: 20px;
            }
            .threeDFrameTitle {
              left: 20px;
            }
          }
        `}
      </style>
      <section className="threeDFrame">
        <header className="threeDFrameTitle">
          <ThreeDButton selectable={false} color={titleColor}>
            <p>{title}</p>
          </ThreeDButton>
        </header>
        <p className="threeDFrameContent">{children}</p>
      </section>
    </div>
  );
};

export default ThreeDFrame;
