import * as React from 'react';
import Stack from './Steps/Stack';
import LoginButton from './LoginButton';
import Dropdown from './Dropdown';
import { AllCourseListQuery } from 'types/graphql-types';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {}

const Nav = ({}: Props) => {
  const data: AllCourseListQuery = useStaticQuery(graphql`
    query AllCourseList {
      getDefaultCourse {
        id
      }
      allCourse {
        nodes {
          id
          name
          comingSoon
          lessons(limit: 1) {
            fields {
              slug
            }
          }
        }
      }
      site {
        siteMetadata {
          mainCourse
        }
      }
    }
  `);
  const steps = data.allCourse.nodes
    .filter(({ id }: { id: string }) => id !== data.getDefaultCourse.id)
    .map(course => ({
      title: course.name || '',
      link: course.lessons[0]?.fields?.slug || '/404',
      comingSoon: course.comingSoon,
    }));

  return (
    <div className="right">
      <style jsx={true}>{`
        .right {
          @p: .flex;
        }
        .element {
          @p: .f14, .ph25, .darkBlue60, .pointer, .itemsCenter, .flex, .relative;
          transition: opacity ease-in-out 0.25s;
        }
        .element:hover {
          @p: .darkBlue;
        }
        .element:hover :global(.dropdown) {
          @p: .o100;
          pointer-events: all;
        }
        .element.login {
          @p: .bgPrimary;
        }
        .triangle {
          @p: .f14, .ml6;
        }
        .element.github {
          @p: .ph25, .flex, .itemsCenter, .pv0;
        }
        @media (max-width: 1050px) {
          .course-dropdown {
            display: none !important;
          }
        }
        .graphql {
          @p: .flex;
        }
      `}</style>
      <div className="element course-dropdown">
        <span>All Courses</span>
        <span className="triangle">▾</span>
        <Dropdown>
          <Stack name="All Courses" steps={steps} showLines={false} />
        </Dropdown>
      </div>
      <div className="element">
        <LoginButton />
      </div>
    </div>
  );
};

export default Nav;
