import React from 'react';
export const P = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-p'].join(' ');
  return <p className={mergedClassname} {...props} />;
};
export const H1 = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-h1'].join(' ');
  return <h1 className={mergedClassname} {...props} />;
};
export const H2 = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-h2'].join(' ');
  return <h2 className={mergedClassname} {...props} />;
};
export const H3 = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-h3'].join(' ');
  return <h3 className={mergedClassname} {...props} />;
};
export const H4 = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-h4'].join(' ');
  return <h4 className={mergedClassname} {...props} />;
};
export const H5 = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-h5'].join(' ');
  return <h5 className={mergedClassname} {...props} />;
};
export const H6 = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-h6'].join(' ');
  return <h6 className={mergedClassname} {...props} />;
};
export const Code = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-code'].join(' ');
  return <code className={mergedClassname} {...props} />;
};
export const UL = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-ul'].join(' ');
  return <ul className={mergedClassname} {...props} />;
};
export const LI = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-li'].join(' ');
  return <li className={mergedClassname} {...props} />;
};
export const OL = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-ol'].join(' ');
  return <ol className={mergedClassname} {...props} />;
};
export const BlockQuote = ({ className, ...props }: any) => {
  const mergedClassname = [className, 'markdown-blockquote'].join(' ');
  return <blockquote className={mergedClassname} {...props} />;
};
