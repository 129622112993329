import React from 'react';
import { Step } from 'src/types';
import DottedListItem from './DottedListItem';
import Duration from 'src/components/Duration';
import Link from 'gatsby-link';
import cn from 'classnames';
import { useLocation } from 'src/utils/hooks';
import { IStepDescription } from '../CourseProvider';
import StepDescription from './StepDescription';
import { ComingSoon } from '../ComingSoon';

interface Props {
  steps: Step[];
  first: boolean;
  last: boolean;
  showDuration?: boolean;
  showDescription?: boolean;
  description?: IStepDescription;
  highlightFirst?: boolean;
  showLines?: boolean;
  showLast?: boolean;
  onClickLink?: () => void;
  stepsActive?: boolean;
  coloredBorder?: boolean;
  dark?: boolean;
}

export default function Steps({
  steps,
  first,
  last,
  description,
  showDescription = false,
  showDuration = true,
  highlightFirst = false,
  showLines = true,
  showLast = true,
  stepsActive = false,
  onClickLink,
  coloredBorder = false,
  dark = false,
}: Props) {
  const location = useLocation();
  return (
    <div className={cn('steps', { dark, coloredBorder, showLines })}>
      <style jsx={true}>{`
        .steps {
          @p: .flex, .flexRow, .w100;
        }
        .steps.showLines .steps-container {
          @p: .bl, .bw2;
        }
        .steps.showLines .steps-container {
          @p: .bBlack20;
        }
        .steps.showLines.dark .steps-container {
          @p: .bWhite20;
        }
        .steps.coloredBorder .steps-container {
          border-left-color: $primary !important;
        }
        .steps .desc-container {
          @p: .flex1;
        }
        .steps-container {
          @p: .flex, .flexColumn, .flex1;
        }
        .steps-item + .steps-item {
          @p: .mt16;
        }
        .list-item {
          @p: .itemsCenter, .flex;
        }
        :global(.first-duration-up) {
          @p: .relative;
          top: -3px;
        }
        div :global(a) {
          @p: .black80, .db, .relative, .z2;
        }
        .active :global(.title) {
          color: $primary !important;
        }
        @media (max-width: 500px) {
          .desc-container {
            display: none;
          }
        }
      `}</style>
      {showDescription ? (
        <div className="desc-container">
          {!!description && <StepDescription dark={dark} description={description} showDuration={showDuration} />}
        </div>
      ) : null}
      <div className="steps-container">
        {steps.map((step, index) => {
          let duration: any = null;
          if (showDuration && step.duration && step.duration > 0) {
            duration = <Duration duration={step.duration || 0} link={step.link} dark={dark} />;
          }
          return (
            <div className="steps-item" key={step.link}>
              {!step.comingSoon ? (
                <Link style={{ flex: 1, lineHeight: 1 }} to={step.link} onClick={onClickLink}>
                  <DottedListItem
                    done={step.completed}
                    key={step.title}
                    light={!dark}
                    first={first && index === 0}
                    last={showLast ? last && index === steps.length - 1 : false}
                    active={step.link === location.pathname || stepsActive}
                    highlightFirst={highlightFirst}
                    showLine={showLines}
                  >
                    <div
                      className={cn('list-item', {
                        active: step.link === location.pathname,
                      })}
                    >
                      <span className="title">{step.title}</span>
                      {duration}
                    </div>
                  </DottedListItem>
                </Link>
              ) : (
                <DottedListItem
                  done={step.completed}
                  key={step.title}
                  light={!dark}
                  first={false}
                  last={false}
                  highlightFirst={highlightFirst}
                  showLine={showLines}
                  disabled={true}
                >
                  <div>
                    <div className="list-item">
                      <span className="title">{step.title}</span>
                      {duration}
                    </div>
                    <ComingSoon small={true} />
                  </div>
                </DottedListItem>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
