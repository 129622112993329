import * as React from 'react';
import Nav from './Nav';
import Logo from './Logo';

interface Props {}

export default function Header({}: Props) {
  return (
    <div className="header">
      <style jsx={true}>{`
        .header {
          @p: .flex, .bb, .bDarkBlue10, .bw2, .justifyBetween, .bgWhite;
          z-index: 1000;
          transform: translate3d(0, 0, 0);
        }
      `}</style>
      <Logo />
      <Nav />
    </div>
  );
}
