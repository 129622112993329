import React from 'react';

export default () => (
  <style jsx={true} global={true}>{`
    .markdown {
      @p: .lhCopy, .black80;
      position: relative;
    }
    .markdown-h1,
    .markdown-h2,
    .markdown-h3,
    .markdown-h4 {
      @p: .lhTitle, .darkBlue, .fw5;
      font-family: 'Zilla Slab';
    }
    .markdown-h1 {
      @p: .f38, .mb25;
    }
    .markdown-h2 {
      @p: .f32;
      margin-top: 48px; /* 2x mt38 due to vertical line */
    }
    .markdown-h3 {
      @p: .f32;
      margin-top: 30px;
    }
    .markdown-h4 {
      margin-top: 20px;
    }
    .markdown-h5 {
      margin-top: 20px;
    }
    .markdown-p {
      @p: .f16;
    }
    .markdown-p,
    .markdown-ul {
      @p: .mt16;
    }
    .fl {
      @p: .flex;
    } /* Text Links */
    .markdown-p .markdown-a,
    .markdown-li .markdown-a {
      @p: .noUnderline;
      color: $primary;
    }
    .markdown-p .markdown-a:hover,
    .markdown-li .markdown-a:hover {
      @p: .underline;
    }
    /* Lists */
    .markdown-ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .markdown-ol {
      @p: .mt25;
      padding-left: 19px;
      list-style-type: decimal;
      counter-reset: list;
    }
    .markdown-ul .markdown-li {
      @p: .relative, .mt10, .pl25;
    }
    .markdown-ol .markdown-li {
      @p: .relative, .mt10, .pl16;
    }
    .markdown-ul .markdown-li:before,
    .markdown-ol .markdown-li:before {
      @p: .absolute;
      left: 8px;
    }
    .markdown-ul .markdown-li:before {
      content: '';
      @p: .bgDarkBlue30, .brPill;
      width: 6px;
      height: 6px;
      left: 8px;
      top: 10px;
    } /* Inline Code Snippets */
    .markdown-p .markdown-code,
    .markdown-li .markdown-code,
    .markdown-h2 .markdown-code,
    .markdown-h3 .markdown-code,
    .markdown-h4 .markdown-code {
      @p: .br2, .dib, .white, .lhSolid;
      padding: 4px 4px 3px;
      background: #34495e;
      vertical-align: text-bottom;
    }
    .markdown-p .markdown-code {
      @p: .f14;
    }
    .container.smallFont .markdown-p .markdown-code {
      @p: .f14;
    }
    .markdown-h2 .markdown-code {
      font-size: 25px;
      padding: 6px 8px 4px;
      position: relative;
      top: 2px;
    }
    .markdown-h3 .markdown-code {
      font-size: 20px;
      padding: 6px 6px 4px;
      position: relative;
      top: 2px;
    }
    .markdown-h4 .markdown-code {
      font-size: 18px;
      padding: 6px 6px 4px;
    } /* Blockquotes */
    .markdown-blockquote {
      @p: .ma0, .pl16;
      padding-bottom: 2px;
      border-left: 4px solid $darkBlue10;
    }
    .markdown-blockquote .markdown-p:first-child {
      @p: .mt0, .pt6;
    }
    .markdown-h2 {
      margin-top: 76px; /* 2x mt38 due to vertical line */
    }
    .markdown-h3 {
      margin-top: 30px;
    }
    .markdown-h4 {
      margin-top: 20px;
    }
    .markdown-p {
      @p: .mt16;
    } /* First child never has top padding */
    .container:first-of-type .markdown:first-child .heading-link:first-child h2,
    .container:first-of-type .markdown:first-child .heading-link:first-child h3,
    .container:first-of-type .markdown:first-child .heading-link:first-child h4,
    .container:first-of-type .markdown:first-child .markdown-p:first-child {
      @p: .mt0;
    } /* Extra Margins for Content Blocks: BlockQuote, Code container */
    .markdown .markdown-codecontainer,
    .markdown .markdown-blockquote,
    .markdown .markdown-ul,
    .markdown .imageContainer {
      @p: .mt16;
      margin-bottom: 30px;
    }
    .gatsby-highlight-code-line {
      background-color: $darkBlue06;
      display: block;
      margin-right: -16px;
      margin-left: -16px;
      padding-right: 16px;
      padding-left: 13px;
      border-left: 3px solid $darkBlue20;
    } /* center captions */
    .markdown .image-wrapper + em,
    .markdown .image-wrapper + span + em {
      @p: .db, .tc, .relative, .center, .darkBlue60, .f14;
      font-style: normal;
      max-width: 620px;
      top: -25px;
    }
    .markdown .katex-display > .katex {
      white-space: normal;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
    .markdown .katex {
      @p: .f16;
    }
  `}</style>
);
